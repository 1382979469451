// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog-index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-til-index-js": () => import("./../../../src/pages/til-index.js" /* webpackChunkName: "component---src-pages-til-index-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */)
}

